import {
	INITIAL_STATE,
	SET_USER,
	SIGN_OUT,
	SIGN_IN,
	CHECK_TOKEN,
	CHANGE_VALIDATE_DATA,
	CHANGE_VALIDATE_PENSIONADO,
	CHANGE_VALIDATE_CORRECCION,
	CHANGE_LECTOR,
	CHANGE_ZOOM,
	CHANGE_DISLEXIC,
	CHANGE_FONT_SIZE,
	CHANGE_GRAY_SCALE,
	CHANGE_CONTRAST,
	CHANGE_MASK,
	SesionState,
	SignInProps,
} from '../types/session';
import {
	createAsyncThunk,
	createReducer,
	PayloadAction,
} from '@reduxjs/toolkit';
import { IUser } from '../../interfaces/user';
import { authAPI } from '../../api/modules/auth';

export const actions = {
	setUser: createAsyncThunk(SET_USER, (user: IUser) => {
		if (user.creditos) {
			delete user.creditos;
		}
		localStorage.setItem('deadToken', user.expires_in);
		// localStorage.setItem('tk', user.token);
		return user;
	}),
	signIn: createAsyncThunk(SIGN_IN, (props: SignInProps) => {
		return authAPI
			.post<
				{ email: string; password: string },
				{
					success: boolean;
					message: string;
					Authorization: string;
					user: IUser;
				}
			>('', props)
			.then((data) => {
				const result = { ...data };
				return Promise.all([result]);
			})
			.then(([user]) => user);
	}),
	signOut: createAsyncThunk(SIGN_OUT, () => {
		// localStorage.clear();
		localStorage.removeItem('deadToken');
		return Promise.resolve();
	}),
	checkToken: createAsyncThunk(CHECK_TOKEN, () => {
		return Promise.resolve();
	}),
	changeValidateData: createAsyncThunk(
		CHANGE_VALIDATE_DATA,
		(value: boolean) => {
			return value;
		}
	),
	changeValidatePensionado: createAsyncThunk(
		CHANGE_VALIDATE_PENSIONADO,
		(value: boolean) => {
			return value;
		}
	),
	changeValidateCorreccion: createAsyncThunk(
		CHANGE_VALIDATE_CORRECCION,
		(value: boolean) => {
			return value;
		}
	),
	changeActiveLector: createAsyncThunk(CHANGE_LECTOR, (value: boolean) => {
		return value;
	}),
	changeZoomCursor: createAsyncThunk(CHANGE_ZOOM, (value: boolean) => {
		return value;
	}),
	changeDislexic: createAsyncThunk(CHANGE_DISLEXIC, (value: boolean) => {
		return value;
	}),
	changeFontSizeLevel: createAsyncThunk(CHANGE_FONT_SIZE, (value: number) => {
		return value;
	}),
	changeGrayScale: createAsyncThunk(CHANGE_GRAY_SCALE, (value: boolean) => {
		return value;
	}),
	changeContrast: createAsyncThunk(CHANGE_CONTRAST, (value: boolean) => {
		return value;
	}),
	changeMask: createAsyncThunk(CHANGE_MASK, (value: boolean) => {
		return value;
	}),
};

export default createReducer(INITIAL_STATE, {
	[actions.setUser.fulfilled.type]: (
		_state: SesionState,
		action: PayloadAction<IUser>
	): SesionState => ({
		user: action.payload,
		isLogged: true,
		tokenExpired: false,
		isImpersonalized: action.payload.isImpersonalized,
		validateDatos: _state.validateDatos,
		validatePensionado: _state.validatePensionado,
		validateCorreccion: _state.validateCorreccion,
		activeLector: _state.activeLector,
		zoomCursorActive: _state.zoomCursorActive,
		activeDislexic: _state.activeDislexic,
		fontSizeLevel: _state.fontSizeLevel,
		activeGrayScale: _state.activeGrayScale,
		activeContrast: _state.activeContrast,
		activeMask: _state.activeMask,
	}),
	[actions.signIn.fulfilled.type]: (
		_state: SesionState,
		action: PayloadAction<IUser>
	): SesionState => {
		return {
			user: action.payload,
			isLogged: true,
			tokenExpired: false,
			isImpersonalized: action.payload.isImpersonalized,
			validateDatos: _state.validateDatos,
			validatePensionado: _state.validatePensionado,
			validateCorreccion: _state.validateCorreccion,
			activeLector: _state.activeLector,
			zoomCursorActive: _state.zoomCursorActive,
			activeDislexic: _state.activeDislexic,
			fontSizeLevel: _state.fontSizeLevel,
			activeGrayScale: _state.activeGrayScale,
			activeContrast: _state.activeContrast,
			activeMask: _state.activeMask,
		};
	},
	[actions.signIn.rejected.type]: (state: SesionState): SesionState => {
		return state;
	},
	[actions.signOut.fulfilled.type]: (state: SesionState): SesionState => ({
		user: null,
		isLogged: false,
		tokenExpired: true,
		isImpersonalized: false,
		validateDatos: false,
		validatePensionado: false,
		validateCorreccion: false,
		activeLector: state.activeLector,
		zoomCursorActive: state.zoomCursorActive,
		activeDislexic: state.activeDislexic,
		fontSizeLevel: state.fontSizeLevel,
		activeGrayScale: state.activeGrayScale,
		activeContrast: state.activeContrast,
		activeMask: state.activeMask,
	}),
	[actions.checkToken.fulfilled.type]: (_state: SesionState): SesionState => {
		return {
			..._state,
			tokenExpired: true,
			isImpersonalized: false,
			validateDatos: _state.validateDatos,
			validatePensionado: _state.validatePensionado,
			validateCorreccion: _state.validateCorreccion,
		};
	},
	[actions.changeValidateData.fulfilled.type]: (
		_state: SesionState,
		action: PayloadAction<boolean>
	): SesionState => {
		return {
			..._state,
			validateDatos: action.payload,
		};
	},
	[actions.changeValidatePensionado.fulfilled.type]: (
		_state: SesionState,
		action: PayloadAction<boolean>
	): SesionState => {
		return {
			..._state,
			validatePensionado: action.payload,
		};
	},
	[actions.changeValidateCorreccion.fulfilled.type]: (
		_state: SesionState,
		action: PayloadAction<boolean>
	): SesionState => {
		return {
			..._state,
			validateCorreccion: action.payload,
		};
	},
	[actions.changeActiveLector.fulfilled.type]: (
		_state: SesionState,
		action: PayloadAction<boolean>
	): SesionState => {
		return {
			..._state,
			activeLector: action.payload,
		};
	},
	[actions.changeZoomCursor.fulfilled.type]: (
		_state: SesionState,
		action: PayloadAction<boolean>
	): SesionState => {
		return {
			..._state,
			zoomCursorActive: action.payload,
		};
	},
	[actions.changeDislexic.fulfilled.type]: (
		_state: SesionState,
		action: PayloadAction<boolean>
	): SesionState => {
		return {
			..._state,
			activeDislexic: action.payload,
		};
	},
	[actions.changeFontSizeLevel.fulfilled.type]: (
		_state: SesionState,
		action: PayloadAction<number>
	): SesionState => {
		return {
			..._state,
			fontSizeLevel: action.payload,
		};
	},
	[actions.changeGrayScale.fulfilled.type]: (
		_state: SesionState,
		action: PayloadAction<boolean>
	): SesionState => {
		return {
			..._state,
			activeGrayScale: action.payload,
		};
	},
	[actions.changeContrast.fulfilled.type]: (
		_state: SesionState,
		action: PayloadAction<boolean>
	): SesionState => {
		return {
			..._state,
			activeContrast: action.payload,
		};
	},
	[actions.changeMask.fulfilled.type]: (
		_state: SesionState,
		action: PayloadAction<boolean>
	): SesionState => {
		return {
			..._state,
			activeMask: action.payload,
		};
	},
});
