import { Bitacora, IBitacora } from '../../interfaces/bitacora';
const _bitacora = new Bitacora('131', 'entrega-escrituras');

export const ENDPOINTS_ENTREGA_ESCRITURAS: IBitacora = {
	'/entrega-escritura-crea-caso': _bitacora.Read('131100', 'Crear caso'),
	'/entrega-escritura-consulta-caso': _bitacora.Read(
		'131101',
		'Consultar casos'
	),
	'/entrega-escritura-valida': _bitacora.Read('131102', 'Validar caso'),
	'/address-catalog': _bitacora.Read('131103', 'Consultar direccion'),
	'/entrega-escritura-obtener-estados': _bitacora.Read(
		'131104',
		'Consultar estados'
	),
	'/entrega-escritura-cesi-estado': _bitacora.Read(
		'131105',
		'Consultar cesi estados'
	),
	'/entrega-escritura-lista-documentos': _bitacora.Read(
		'131106',
		'Consultar lista de documentos'
	),
	'/entrega-escritura-subir-documentos': _bitacora.Read(
		'131107',
		'Subir documentos'
	),
	'/entrega-escritura-sin-documentos': _bitacora.Read(
		'131108',
		'Continuar sin documentos'
	),
	// --------------- Nuevas APIs --------------------------------------------
	'/entrega-escritura/consulta-caso': _bitacora.Read(
		'131109',
		'Consultar casos'
	),
	'/entrega-escritura/generico-valida': _bitacora.Read(
		'131110',
		'Consultar validación genérica'
	),
	'/entrega-escritura/catalogo-estado': _bitacora.Read(
		'131111',
		'Consultar catalogo de estado'
	),
	'/entrega-escritura/catalogo-cesi': _bitacora.Read(
		'131112',
		'Consultar catalogo de Cesis'
	),
	'/entrega-escritura/crea-caso': _bitacora.Create(
		'131113',
		'Crea caso'
	),
	'/entrega-escritura/consulta-detalle-caso': _bitacora.Read(
		'131114',
		'Consultar detalle del caso'
	),
	'/entrega-escritura/consulta-documentos': _bitacora.Read(
		'131115',
		'Consultar documentos'
	),
	'/entrega-escritura/adjunta-documentos': _bitacora.Update(
		'131116',
		'Adjunta sin documentos'
	),
	'/entrega-escritura/actualiza-estatus': _bitacora.Update(
		'131117',
		'Actualizar estatus'
	),
	// --------------- Todo relacionado con las citas ----------------------------
	'/v1/citas-24/obtener-requisitos': _bitacora.Read(
		'131118',
		'Consultar requisitos de la cita'
	),
	'/v1/citas-24/obtener-regiones': _bitacora.Read(
		'131118',
		'Consultar regiones de la cita'
	),
	'/v1/citas-24/obtener-sucursales': _bitacora.Read(
		'131118',
		'Consultar Sucursales de la cita'
	),
	'/v1/citas-24/obtener-programacion-mensual': _bitacora.Read(
		'131118',
		'Consultar programación mensual de cita'
	),
	'/v1/citas-24/obtener-programacion-laboral': _bitacora.Read(
		'131118',
		'Consultar programación laboral de cita'
	),
	'/v1/citas-24/reservar-cita': _bitacora.Update(
		'131118',
		'Reservar cita'
	),
	'/v1/citas-24/crear-cita': _bitacora.Update(
		'131118',
		'Crear o Actualizar cita'
	),
	'/v1/citas-24/cancelar-cita': _bitacora.Update(
		'131118',
		'Cancelar cita'
	),
	'/v1/citas-24/obtener-cita': _bitacora.Read(
		'131118',
		'Consultar cita'
	),
	'/v1/citas-24/generar-comprobante': _bitacora.Read(
		'131118',
		'Obtener comprobante de cita'
	),
};
