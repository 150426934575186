import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	containerModalView: {
		textAlign: 'center',
		maxWidth: 600,
		width: '100%',
		height: 600,
	},
	iframeModalView: {
		maxWidth: 600,
		width: '100%',
		height: 600,
	},
	videoModalView: {
		maxWidth: 550,
		width: '100%',
	},
	TextBlack_H3: {
		fontWeight: 'bold',
		fontSize: 18,
	},
	textTopLeft: {
		position: 'absolute',
		top: '30px',
		left: '30px',
	},
	divContainer: {
		position: 'relative',
		padding: '40px 120px',
		[theme.breakpoints.down('md')]: {
			padding: '40px 80px',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '40px 40px',
		},
	},
	divChecks: {
		marginTop: '0px',
	},
	iconsImg: {
		width: 16,
		marginRight: 5,
	},
	txtPrincipales: {
		fontSize: '14px !important',
		verticalAlign: 'top',
		letterSpacing: 0,
		opacity: 1,
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px !important',
		},
	},
	CheckParagraph: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		paddingLeft: '25px',
		[theme.breakpoints.down('md')]: {
			paddingLeft: '15px',
		},
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '15px',
		},
	},
	divButtons: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column-reverse',
		},
	},
	divButtonsNoReverse: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		minWidth: '40%',
		// marginTop: '5px',
		marginBottom: '20px',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			minWidth: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			minWidth: '100%',
		},
	},
	divButtons3: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		width: '100%',
		marginTop: '10px',
		marginBottom: '20px',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column-reverse',
			width: '280px',
		},
	},
	divNRP: {
		width: '600px',
		marginTop: '20px',
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	divAlert: {
		display: 'flex',
		justifyContent: 'center',
		paddingLeft: '20%',
		paddingRight: '20%',
		[theme.breakpoints.down('md')]: {
			paddingLeft: '0%',
			paddingRight: '0%',
		},
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '0%',
			paddingRight: '0%',
		},
	},
	Buttons: {
		width: 260,
		margin: '30px 10px 0',
		[theme.breakpoints.down('md')]: {
			margin: '30px auto 0',
		},
	},
	divTotalSaving: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: '35px',
	},
	divTotalSavingInfo: {
		border: '2px solid #293990',
		color: '#293990',
		paddingTop: 10,
		paddingBottom: 10,
		paddingLeft: 70,
		paddingRight: 70,
	},
	divTextCentered: {},
	containerFiles: {
		display: 'flex',
		flexWrap: 'wrap',
		paddingTop: 15,
		paddingBottom: 5,
	},
	containerFilesOne: {
		width: '40%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			textAlign: 'center',
		},
	},
	containerFilesTwo: {
		padding: '2px 20px',
		width: '30%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			textAlign: 'center',
			margin: '30px auto',
		},
	},
	containerFilesThree: {
		width: '30%',
		textAlign: 'right',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			textAlign: 'center',
		},
	},
	textFile: {
		fontSize: 16,
		color: '#7F8487',
		margin: '0 0 10px',
		lineHeight: '18px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	containerButtons: {
		width: '540px',
		margin: '0 auto',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			flexDirection: 'column',
			margin: '20px auto 0',
		},
	},
	contentFile: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			//justifyContent: 'flex-start',
			alignItems: 'start',
			flexDirection: 'column',
		},
		[theme.breakpoints.down('md')]: {
			//justifyContent: 'flex-start',
			alignItems: 'start',
			flexDirection: 'column',
		},
	},
	contentFileData: {
		width: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			display: 'block',
		},
		[theme.breakpoints.down('md')]: {},
	},
	contentFileDataTitle: {
		textAlign: 'left',
		fontSize: 15,
		minWidth: 250,
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem',
			minWidth: 200,
		},
		[theme.breakpoints.down('md')]: {},
	},
	contentFileDataText: {
		fontSize: 15,
		color: '#7F7F7F',
		[theme.breakpoints.down('sm')]: {
			fontSize: 13,
			textAlign: 'left',
		},
		[theme.breakpoints.down('md')]: {},
	},
	contentFileDataLink: {
		color: '#D1001F',
		fontSize: 14,
		[theme.breakpoints.down('sm')]: {
			fontSize: 13,
		},
	},
	divRowFilesButtons: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'left',
		[theme.breakpoints.down('sm')]: {},
		[theme.breakpoints.down('md')]: {},
	},
	divTextInputPassword: {
		width: '50%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		[theme.breakpoints.down('md')]: { width: '100%' },
	},
	divFechaResolution: {
		width: '43%',
		paddingLeft: '15px',
		paddingRight: '15px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			paddingLeft: '10px',
			paddingRight: '10px',
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
			paddingLeft: '10px',
			paddingRight: '10px',
			marginBottom: 20,
		},
	},
	divFechas: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '20px',
		flexDirection: 'row',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
		},
	},
	divFechasButtons: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		marginTop: '20px',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column-reverse',
		},
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column-reverse',
		},
	},
	ResolutionButtons: {
		width: 260,
		margin: '20px 10px 0',
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	divContainerInputs: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
	},
	titleNotice: {
		marginTop: 0,
		fontSize: 22,
		color: '#293990',
	},
	contentFooterDataLink: {
		color: '#D1001F',
		display: 'flex',
		fontSize: '15px',
		alignItems: 'center',
		justifyContent: 'center',
	},
	containerModalInfo: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
	},
	p: {
		color: '#7F8487',
		margin: '0 0 10px',
		lineHeight: '18px',
	},
	message: {
		textAlign: 'center',
		margin: '40px auto 20px',
	},
	form: {
		width: '100%',
		maxWidth: '1000px',
		margin: '0 auto',
	},
	divRowInfo: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
		padding: '5px 0',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			justifyContent: 'center',
		},
	},
	divRowTooltip: {
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
		},
	},
	divRowInfo_Cantidad: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '0px',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
			marginBottom: '10px',
			marginTop: '20px',
		},
	},
	divTextSubHeader: {
		lineHeight: '0px',
		[theme.breakpoints.down('md')]: {
			lineHeight: '1px',
		},
		[theme.breakpoints.down('sm')]: {
			lineHeight: '1px',
		},
	},
	maxContent: {
		width: '100%',
		maxWidth: 'max-content'
	},
	RowInfoH3: {
		lineHeight: '0px',
		[theme.breakpoints.down('sm')]: {
			lineHeight: '10px',
		},
	}
}));
