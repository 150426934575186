/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { contactRequestAPI } from '../../../api/modules/profile';
import {
	defaultUserCredentialsData,
	IContactData,
	ICredentials,
} from '../interfaces/register.interface';
import { IAlert } from '../../../interfaces/alert';
import { createAccountAPI } from '../../../api/modules/createAccount';
import { INTERNAL_MESSAGES } from '../../../config/messageCatalog';
import { encrypt } from '../../../utils/encrypt';
import { keyAPI } from '../../../api/modules/key';
import { isNumber, emailValid } from '../../../utils/expressions';

export const useContactDataRegister = (props: IContactData) => {
	const { onContinue, onChange, hasError, onCloseError } = props;
	const [credentials, setCredentials] = useState<ICredentials>(
		defaultUserCredentialsData
	);
	const [buttonValidate, setButtonValidate] = useState(true);
	const [emailValidate, setEmailValidate] = useState({
		valid: false,
		msg: '',
	});
	const [email1Validate, setEmail1Validate] = useState({
		valid: false,
		msg: '',
	});
	const [phoneValidate, setPhoneValidate] = useState({
		valid: false,
		msg: '',
	});
	const [phone1Validate, setPhone1Validate] = useState({
		valid: false,
		msg: '',
	});
	const [radioButton, setRadioButton] = useState(0);
	const [modalOpenPriv, setModalOpenPriv] = useState(false);
	const [modalOpenTerm, setModalOpenTerm] = useState(false);
	const [modalButtonPriv, setModalButtonPriv] = useState(true);
	const [modalButtonTerm, setModalButtonTerm] = useState(true);
	const [loader, setLoader] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		message: '',
		severity: 'success',
		show: false,
	});
	const [errorValidationPhone, setErrorValidationPhone] = useState(false);
	const [errorValidationEmail, setErrorValidationEmail] = useState(false);

	useEffect(() => {
		if (
			emailValidate.valid &&
			phoneValidate.valid &&
			radioButton > 0 &&
			credentials.phone === credentials.phone1 &&
			credentials.email === credentials.email1
		) {
			setButtonValidate(false);
		} else {
			setButtonValidate(true);
		}
	}, [radioButton, emailValidate, phoneValidate, credentials]);

	useEffect(() => {
		if (credentials.phone !== credentials.phone1) {
			if (!credentials.phone1) {
				setPhone1Validate({
					valid: false,
					msg: '',
				});
			} else {
				setPhone1Validate({
					valid: false,
					msg: INTERNAL_MESSAGES.CHECK_PHONE_NUMBER,
				});
			}
		} else {
			setPhone1Validate({ valid: true, msg: '' });
		}
	}, [credentials.phone1]);

	useEffect(() => {
		if (credentials.email !== credentials.email1) {
			if (!credentials.email1) {
				setEmail1Validate({
					valid: false,
					msg: '',
				});
			} else {
				setEmail1Validate({
					valid: false,
					msg: INTERNAL_MESSAGES.CHECK_EMAIL,
				});
			}
		} else {
			setEmail1Validate({ valid: true, msg: '' });
		}
	}, [credentials.email1]);

	const setData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		let validate = null;

		if (name === 'phone') {
			if (!isNumber(value)) return;
			if (value.length > 10) return;
			if (value.length < 10) {
				setPhoneValidate({
					valid: false,
					msg: INTERNAL_MESSAGES.LAST_TEN_DIGITS_CELL_PHONE,
				});
			} else {
				setPhoneValidate({
					valid: false,
					msg: '',
				});
			}
			if (!value) {
				setPhoneValidate({
					valid: false,
					msg: '',
				});
			}
			onChange(name, value);
		}
		if (name === 'phone1') {
			if (!isNumber(value)) return;
			if (value.length > 10) return;
		}
		if (name === 'email') {
			if (!value) {
				setEmailValidate({
					msg: '',
					valid: false,
				});
			}
			validate = emailValid(value);
			setEmailValidate(validate);
			onChange(name, value);
		}
		if (name === 'email1') {
			if (!value) {
				setEmail1Validate({
					msg: '',
					valid: false,
				});
			}
			validate = emailValid(value);
			setEmail1Validate(validate);
		}
		setCredentials({ ...credentials, [name]: value });
	};

	const handleOnChangeRadioButton = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		const { name } = event.target;
		setRadioButton(
			event.target.checked ? radioButton + 1 : radioButton - 1
		);
		onChange('byPoE', name === 'Por celular' ? 'byPhone' : 'byEmail');
	};

	const handleOnChangeModalButtonPriv = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		setModalButtonPriv(!event.target.checked);
	};

	const handleOnChangeModalButtonTerm = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		setModalButtonTerm(!event.target.checked);
	};

	const handleValidateEmail = async () => {
		if (!emailValidate.valid) return;
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const email_base64 = await encrypt(credentials.email || '', key);
			createAccountAPI
				.validateEmailAddress(email_base64)
				.then(() => {
					setEmailValidate({ msg: '', valid: true });
				})
				.catch((err) => {
					if (err.description) {
						setEmailValidate({
							msg: err.description,
							valid: false,
						});
					}
				});
		} catch (error) {}
	};

	const handleValidatePhone = async () => {
		if (!credentials.phone) {
			setPhoneValidate({
				msg: '',
				valid: false,
			});
			return;
		}
		if (credentials.phone.length < 10) {
			setPhoneValidate({
				msg: INTERNAL_MESSAGES.LAST_TEN_DIGITS_CELL_PHONE,
				valid: false,
			});
			return;
		}
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const phone_base64 = await encrypt(credentials.phone, key);
			createAccountAPI
				.validatePhone(phone_base64)
				.then((res) => {
					setPhoneValidate({ msg: '', valid: true });
				})
				.catch((err) => {
					if (err.description) {
						setPhoneValidate({
							msg: err.description,
							valid: false,
						});
					}
				});
		} catch (error) {}
	};

	const initialConfirmPhone = async () => {
		setAlert({
			show: false,
			message: '',
			severity: 'success',
		});
		try {
			setLoader(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const phone_base64 = await encrypt(credentials.phone || '', key);
			contactRequestAPI.validationPhone
				.post<{ celular: string }, any>('', {
					celular: phone_base64,
				})
				.then((res) => {
					if (res?.result?.codigo && res?.result?.codigo === '1') {
						setErrorValidationPhone(false);
						secondConfirmEmail();
					} else {
						if (errorValidationPhone) {
							setAlert({
								show: true,
								message:
									!res.result.codigo && res?.result?.message
										? res.result.message
										: INTERNAL_MESSAGES.CELL_PHONE_NOT_EXIST,
								severity: 'error',
							});
						}
						setErrorValidationPhone(true);
					}
				})
				.catch((e) => {
					setAlert({
						show: true,
						message: e.description
							? e.description
							: INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
					throw e;
				})
				.finally(() => setLoader(false));
		} catch (error: any) {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoader(false);
		}
	};

	const secondConfirmEmail = async () => {
		setAlert({
			show: false,
			message: '',
			severity: 'success',
		});
		try {
			setLoader(true);
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const email_pass_base64 = await encrypt(
				credentials.email || '',
				key
			);
			contactRequestAPI.validationEmail
				.post<{ email: string }, any>('', {
					email: email_pass_base64,
				})
				.then((res) => {
					if (
						res?.result?.emailCodigoEstatus &&
						res?.result?.emailCodigoEstatus === '01'
					) {
						setErrorValidationEmail(false);
						setModalOpenPriv(true);
					} else {
						if (errorValidationEmail) {
							setAlert({
								show: true,
								message:
									!res.result.emailCodigoEstatus &&
									res?.result?.message
										? res.result.message
										: INTERNAL_MESSAGES.NOT_EXISTING_MAIL,
								severity: 'error',
							});
						}
						setErrorValidationEmail(true);
					}
				})
				.catch((e) => {
					setAlert({
						show: true,
						message: e.description
							? e.description
							: INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => setLoader(false));
		} catch (error: any) {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setLoader(false);
		}
	};

	return {
		setData,
		handleOnChangeRadioButton,
		handleOnChangeModalButtonPriv,
		handleOnChangeModalButtonTerm,
		handleValidateEmail,
		handleValidatePhone,
		initialConfirmPhone,
		loader,
		errorValidationPhone,
		errorValidationEmail,
		credentials,
		phoneValidate,
		phone1Validate,
		emailValidate,
		email1Validate,
		hasError,
		buttonValidate,
		alert,
		modalOpenPriv,
		setModalButtonPriv,
		setModalOpenPriv,
		modalOpenTerm,
		setModalOpenTerm,
		setModalButtonTerm,
		onContinue,
		onCloseError,
		secondConfirmEmail,
		modalButtonPriv,
		modalButtonTerm,
	}
}