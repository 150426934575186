import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		header: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		buttonLarge: {
			width: '100%',
			// padding: `${theme.spacing(0.5)}px ${theme.spacing(6)}px`,
			height: 40,
			maxWidth: 250,
		},
		title: {
			width: '100%',
			fontSize: 26,
			margin: 0,
		},
		title1: {
			fontSize: 27,
			'& *': {
				fontSize: '27px !important',
			}
		},
		title2: {
			fontSize: 28,
			'& *': {
				fontSize: '28px !important',
			}
		},
		title3: {
			fontSize: 29,
			'& *': {
				fontSize: '29px !important',
			}
		},
		title4: {
			fontSize: 30,
			'& *': {
				fontSize: '30px !important',
			}
		},
		title5: {
			fontSize: 31,
			'& *': {
				fontSize: '31px !important',
			}
		},
		titleMin1: {
			fontSize: 25,
		},
		titleMin2: {
			fontSize: 24
		},
		titleMin3: {
			fontSize: 23
		},
		buttonLargeSize: {
			fontSize: 14
		},
		buttonLargeSize1: {
			fontSize: 15
		},
		buttonLargeSize2: {
			fontSize: 16
		},
		buttonLargeSize3: {
			fontSize: 17
		},
		buttonLargeSize4: {
			fontSize: 18
		},
		buttonLargeSize5: {
			fontSize: 19
		},
		buttonLargeSizeMin1: {
			fontSize: 13
		},
		buttonLargeSizeMin2: {
			fontSize: 12
		},
		buttonLargeSizeMin3: {
			fontSize: 11
		}
	})
);
