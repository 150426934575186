import {
	nssValid,
	isNumber,
	rfcValid,
	curpValid,
} from '../../../utils/expressions';

import { INTERNAL_MESSAGES } from '../../../config/messageCatalog';
import { IAlert } from '../../../interfaces/alert';
import { MESSAGES, USERS_BLOCKED } from '../../Main/utils/profilesData';
import { createAccountAPI } from '../../../api/modules/createAccount';
import { keyAPI } from '../../../api/modules/key';
import { encrypt } from '../../../utils/encrypt';
import { ICredentialsPersonalData, IUserData } from '../interfaces/register.interface';
import { useState } from 'react';


interface IPersonalDataHook {
	setDataUser: (data: IUserData) => void;
	onChange: (name: string, value: string | boolean) => void;
	onContinueNSS?: (value: number) => void;
}
export const usePersonalDataRegister = ({ setDataUser, onChange, onContinueNSS }: IPersonalDataHook) => {
	const [credentials, setCredentials] = useState<ICredentialsPersonalData>({
		nss: '',
		rfc: '',
		curp: '',
	});
	const [errorNSS, setErrorNSS] = useState({ valid: false, msg: '' });
	const [errorRFC, setErrorRFC] = useState<{
		valid: boolean;
		msg: string;
		code?: string | undefined;
	}>({ valid: false, msg: '', code: '' });
	const [errorCURP, setErrorCURP] = useState({ valid: false, msg: '' });
	const [loading, setLoading] = useState(false);
	const [code, setCode] = useState('');
	const [codeInput, setCodeInput] = useState('');
	const [modalLogged, setModalLogged] = useState(false);
	const [modalLocked, setModalLocked] = useState(false);
	const [modalPrivacy, setModalPrivacy] = useState(true);
	const [nssValidateFinal, setNssValidateFinal] = useState(false);
	const [curpValidateFinal, setCurpValidateFinal] = useState(false);
	const [rfcValidateFinal, setRfcValidateFinal] = useState(false);
	const [modalCorrectionRfc, setModalCorrectionRfc] = useState(false);
	const [name, setName] = useState('');
	const [errorRfcFinal, setErrorFinal] = useState(false);
	const [rfcCrm, setRfcCrm] = useState('');
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});

	const setData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		let validate = null;

		if (name === 'nss') {
			if (!isNumber(value)) return;
			if (value.length > 11) return;
			if (!value) {
				setErrorNSS({ valid: false, msg: '' });
			}
			validate = nssValid(value);
			setErrorNSS(validate);
		}
		if (name === 'rfc') {
			if (value.length > 13) return;
			if (!value) {
				setErrorRFC({ valid: false, msg: '' });
			}
			validate = rfcValid(value);
			setErrorRFC(validate);
		}
		if (name === 'curp') {
			let validar: boolean = true;
			if (value.length > 18) return;

			if (value.length === 0) {
				setErrorCURP({
					valid: false,
					msg: INTERNAL_MESSAGES.PROVIDES_CURP,
				});
				validar = false;
			}

			if (value.length > 0 && value.length < 18) {
				setErrorCURP({
					valid: false,
					msg: INTERNAL_MESSAGES.CURP_MISSING_CHARACTERS,
				});
				validar = false;
			}
			if (validar) {
				validate = curpValid(value);
				setErrorCURP(validate);
			}
		}
		setCredentials({ ...credentials, [name]: value.toUpperCase() });
		onChange(name, value);
	};

	const handleValidateNSS = async () => {
		if (!errorNSS.valid) return;
		if (USERS_BLOCKED.includes(credentials.nss)) {
			setErrorNSS({ msg: MESSAGES.USERS_BLOCKED, valid: false });
		} else {
			setLoading(true);
			try {
				const keyRes = await keyAPI.get<'', { k: string }>();
				const key = keyRes['k'].toString();
				const code_base64 = await encrypt(credentials.nss || '', key);
				createAccountAPI
					.validateNSS(code_base64)
					.then((res) => {
						setErrorNSS({ msg: '', valid: true });
						setNssValidateFinal(true);
						setName(
							`${res.result?.nombre} ${res.result?.apPaterno} ${res.result?.apMaterno}`
						);
						if (res?.result) {
							onChange(
								'name',
								`${res.result?.nombre} ${res.result?.apPaterno}  ${res.result?.apMaterno} `
							);
						}
						if (res.result?.currentStep && onContinueNSS) {
							setDataUser(res.result);
							onChange(
								'name',
								`${res.result?.nombreDACI} ${res.result?.apPaternoDACI}  ${res.result?.apMaternoDACI} `
							);
							onContinueNSS(res.result?.currentStep);
						}
					})
					.catch((err) => {
						if (err?.description) {
							let errorFlag = err.description;
							if (
								err.secondDescription ===
								INTERNAL_MESSAGES.ACTIVE_ACCOUNT
							) {
								setModalLogged(!modalLogged);
							}
							if (errorFlag === INTERNAL_MESSAGES.USER_BLOCKED) {
								setModalLocked(!modalLocked);
							}
							if (
								errorFlag ===
									'Cuenta en proceso de Unificación' ||
								errorFlag ===
									'Cuenta con marca de fallecido en SACI'
							) {
								errorFlag = err.message;
							}
							setErrorNSS({ msg: errorFlag, valid: false });
						}
					})
					.finally(() => setLoading(false));
			} catch (error: any) {
				setNssValidateFinal(false);
				setErrorNSS({
					msg:
						error?.description ||
						'Por el momento el servicio no se encuentra disponible, intenta más tarde',
					valid: false,
				});
				setLoading(false);
			}
		}
	};

	const handleValidateCURP = async () => {
		if (!errorCURP.valid) return;
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(credentials.nss || '', key);
			const curp_base64 = await encrypt(credentials.curp || '', key);
			createAccountAPI
				.validateCURP(nss_base64, curp_base64)
				.then((res) => {
					if (res?.result) {
						setDataUser(res.result);
						onChange(
							'name',
							`${res.result?.nombreDACI} ${res.result?.apPaternoDACI}  ${res.result?.apMaternoDACI} `
						);
						setCurpValidateFinal(true);
						setRfcCrm(res.result?.rfcCrm);
					}
					if (res?.description) {
						if (
							res.description ===
							'Por el momento el servicio no se encuentra disponible, intenta más tarde'
						) {
							setErrorCURP({
								msg: res.description,
								valid: false,
							});
							setCurpValidateFinal(false);
						} else {
							setErrorCURP({ msg: res.description, valid: true });
							setCurpValidateFinal(true);
						}
					} else {
						setErrorCURP({ msg: '', valid: true });
						setCurpValidateFinal(true);
					}
				})
				.catch((err: any) => {
					setCurpValidateFinal(false);
					setErrorCURP({
						msg: err.description
							? err.description
							: 'Por el momento el servicio no se encuentra disponible, intenta más tarde',
						valid: false,
					});
				})
				.finally(() => setLoading(false));
		} catch (err: any) {
			setCurpValidateFinal(false);
			setErrorCURP({
				msg: err.description
					? err.description
					: 'Por el momento el servicio no se encuentra disponible, intenta más tarde',
				valid: false,
			});
			setLoading(false);
		}
	};

	const handleValidateRFC = async () => {
		if (!errorRFC.valid) return;
		setErrorFinal(false);
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(credentials.nss || '', key);
			const rfc_base64 = await encrypt(credentials.rfc || '', key);
			createAccountAPI
				.validateRFC(nss_base64, rfc_base64)
				.then((res) => {
					if (Object.entries(res).length === 0) {
						setErrorRFC({ msg: '', valid: true, code: '' });
						setRfcValidateFinal(true);
					}

					if (res?.description) {
						if (
							res.description ===
							'Por el momento el servicio no se encuentra disponible, intenta más tarde'
						) {
							setErrorRFC({
								msg: res.description,
								valid: false,
								code: '',
							});
							setRfcValidateFinal(false);
						} else {
							setErrorRFC({
								msg: res.description,
								valid: true,
								code: '',
							});
							setRfcValidateFinal(true);
							setErrorFinal(true);
						}
					} else if (res?.result?.description) {
						if (
							res.result.description ===
							'Por el momento el servicio no se encuentra disponible, intenta más tarde'
						) {
							setErrorRFC({
								msg: res.result.description,
								valid: false,
								code: '',
							});
							setRfcValidateFinal(false);
						} else {
							setErrorRFC({
								msg: res.result.description,
								valid: true,
								code: '',
							});
							setRfcValidateFinal(true);
							setErrorFinal(true);
						}
					} else {
						setErrorRFC({ msg: '', valid: true, code: '' });
						setRfcValidateFinal(true);
					}
				})
				.catch((err: any) => {
					if (err?.description) {
						if (
							err.description ===
							'Por el momento el servicio no se encuentra disponible, intenta más tarde'
						) {
							setErrorRFC({
								msg: err?.description,
								valid: false,
								code: err?.code,
							});
							setRfcValidateFinal(false);
						} else {
							setErrorRFC({
								msg: err?.description,
								valid: true,
								code: err?.code,
							});
							setRfcValidateFinal(true);
							setErrorFinal(true);
						}
					}
				})
				.finally(() => setLoading(false));
		} catch (err: any) {
			if (err?.description) {
				if (
					err.description ===
					'Por el momento el servicio no se encuentra disponible, intenta más tarde'
				) {
					setErrorRFC({
						msg: err?.description,
						valid: false,
						code: err?.code,
					});
					setRfcValidateFinal(false);
				} else {
					setErrorRFC({
						msg: err?.description,
						valid: true,
						code: err?.code,
					});
					setRfcValidateFinal(true);
					setErrorFinal(true);
				}
			}
			setLoading(false);
		}
	};

	const deletePreRegister = async () => {
		setLoading(true);
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const nss_base64 = await encrypt(credentials.nss, key);
			createAccountAPI
				.deletePreRegister(nss_base64)
				.then((res) => {
					setModalCorrectionRfc(true);
				})
				.catch((err: any) => {
					setAlert({
						show: true,
						message: err.description
							? err.description
							: 'Por el momento el servicio no está disponible. Intenta más tarde.',
						severity: 'error',
					});
				})
				.finally(() => setLoading(false));
		} catch (err: any) {
			setAlert({
				show: true,
				message: err.description
					? err.description
					: 'Por el momento el servicio no está disponible. Intenta más tarde.',
				severity: 'error',
			});
			setLoading(false);
		}
	};

	return {
		credentials,
		setData,
		handleValidateNSS,
		handleValidateCURP,
		handleValidateRFC,
		deletePreRegister,
		errorNSS,
		errorCURP,
		nssValidateFinal,
		errorRFC,
		curpValidateFinal,
		errorRfcFinal,
		codeInput,
		setCodeInput,
		setCode,
		rfcValidateFinal,
		code,
		modalLogged,
		setModalLogged,
		modalCorrectionRfc,
		setModalCorrectionRfc,
		loading,
		modalLocked,
		setModalLocked,
		modalPrivacy,
		setModalPrivacy,
		alert,
		name,
		rfcCrm
	}
}