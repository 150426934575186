/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Grid } from '@mui/material';
import { useStyles } from './styles';

import { useNavigate } from 'react-router-dom';
//Assets

import imgAltanto from '../../../assets/img/mantente-al-tanto.png';
import imgChecaContacto from '../../../assets/img/ChecaDatosConto.png';
import imgAgendaCita from '../../../assets/img/Agenda-tu-cita.png';
import imgInsSocio from '../../../assets/img/inscribete-socio.png';
import imgPagaMenos from '../../../assets/img/paga-menos.png';

const PensionerFulfilledCredit = () => {
	const classes = useStyles();

	const navigate = useNavigate();

	return (
		<Grid container spacing={2}>
			<Grid item sm={12} md={12} lg={4}>
				<Paper className={classes.card}>
					<div className={classes.titleCard}>
						<h3>
							¿Estás a punto de pensionarte o eres pensionado(a)
							y aún no terminas de pagar tu crédito?
						</h3>
					</div>
					<div className={classes.textCard}>
						<p>
							Consulta cuánto debes en Saldos y movimientos.
							Recuerda que puedes adelantar pagos sin penalización
							alguna para terminar de pagar anticipadamente.
						</p>
						<p>
							En nuestros canales de atención pregunta acerca del
							apoyo para pensionados, ya con tu resolución de
							pensión emitida por el IMSS, ISSSTE o si alguna
							vez también trabajaste para el Estado, puedes ajustar
							tu mensualidad al importe mensual que te dan por pensión.
						</p>
						<p>
							Si tienes duda de dónde continuar realizando tus pagos,
							ingresa a Pago en línea para que desde ahí pagues o
							consultes el listado de lugares de pago.
						</p>
						<p>
							Recuerda que el Infonavit te brinda apoyos ante
							Incapacidades parciales permanentes, incapacidades
							totales permanentes y defunción.
						</p>
						<br />
					</div>
				</Paper>
			</Grid>

			<Grid item sm={12} md={12} lg={8}>
				<Grid container spacing={2}>
					<Grid item sm={6} md={6} lg={6}>
						<div
							onClick={() =>
								navigate('/mi-credito/saldos-movimientos')
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgAltanto}
								className={classes.imageStyle}
								alt="Mantente al tanto con toda la información de tu saldo y movimientos de tu credito"
							/>
						</div>
					</Grid>
					<Grid item sm={6} md={6} lg={6}>
						<Grid item lg={12}>
							<div
								onClick={() =>
									navigate('/contactanos/haz-una-cita')
								}
								style={{ height: '100%' }}
							>
								<img
									src={imgAgendaCita}
									className={classes.imageStyle}
									alt="Agenda tu cita"
								/>
							</div>
						</Grid>
						<Grid item lg={12} mt={2}>
							<div
								onClick={() =>
									navigate(
										'/mi-credito/constancia-intereses-declaracion-anual'
									)
								}
								style={{ height: '100%' }}
							>
								<img
									src={imgPagaMenos}
									className={classes.imageStyle}
									alt="Constancia de intereses"
								/>
							</div>
						</Grid>
					</Grid>
				</Grid>
				<Grid container direction="row" spacing={2} mt={1}>
					<Grid item sm={6} md={6} lg={6}>
						<div
							onClick={() =>
								navigate('/mi-perfil/actualizar-datos-contacto')
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgChecaContacto}
								className={classes.imageStyle}
								alt="actualizar datos contacto"
							/>
						</div>
					</Grid>
					<Grid item sm={6} md={6} lg={6}>
						<div
							onClick={() =>
								navigate('/mi-perfil/socio-infonavit')
							}
							style={{ height: '100%' }}
						>
							<img
								src={imgInsSocio}
								className={classes.imageStyle}
								alt="Inscríbete a Socio Infonavit"
							/>
						</div>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default PensionerFulfilledCredit;
