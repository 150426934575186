/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
// Dependencies
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import jwt from 'jwt-decode';

// Components
import { Grid, Paper } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../../components/Button';
import TextInput from '../../components/TextInput';
import BaseModal from '../../components/Modal';
import ModalLoading from '../../components/ModalLoading';
import CustomAlert from '../../components/CustomAlert';
import ModalConfirmation from '../../components/ModalConfirmation';
import ExpandButton from '../../components/FloatExpandButton';
import Captcha from '../../components/Captcha';
import { clsAssistantVirtual } from '../../components/AssistantVirtual';
import {
	showModal,
	setInitPoll,
	ModalAuxiliar,
} from '../../components/CalificaVirtual';

// Resources
import Footer from '../../components/Footer';
import { nssValid, isNumber } from '../../utils/expressions';
import { authAPI, validateNss } from '../../api/modules/auth';
import { actions } from '../../store/modules/session';
import { actions as creditsAction } from '../../store/modules/credit';
import { actions as saldAction } from '../../store/modules/saldo';
import { actions as bitacoraAction } from '../../store/modules/bitacora';
import { IAlert } from '../../interfaces/alert';
import { encrypt } from '../../utils/encrypt';
import { keyAPI } from '../../api/modules/key';
import { isEmpty } from '../../utils/validators';
import { INTERNAL_MESSAGES } from '../../config/messageCatalog';
import { PROFILES, MESSAGES, USERS_BLOCKED } from '../Main/utils/profilesData';

// Assets
import { useStyles } from './styles';
import logoLogin from '../../assets/svg/logo-MCI-2024.svg';
import warning from '../../assets/img/icono-advertencia-rojo.png';
import iconAttention from '../../assets/img/icono-oficinas-de-atn.png';
import iconServices from '../../assets/img/icono-canales-de-servicio.png';
import chatBot from '../../assets/img/chatbot-icono.jpg';
import likeIcon from '../../assets/img/icono-experiencia.png';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import AccessibilityTool from '../../components/AccessibilityTool';
import { RootState } from '../../store';
// @ts-ignore
interface ICredentials {
	nss: string;
	password: string;
}

const clasChat = new clsAssistantVirtual();

const Login = () => {
	const { fontSizeLevel } = useSelector((state: RootState) => state.session);
	const [credentials, setCredentials] = useState<ICredentials>({
		nss: '',
		password: '',
	});
	const [errorNSS, setErrorNSS] = useState({ valid: false, msg: '' });
	const [modalConfirm, setModalConfirm] = useState(false);
	const [modalLockedUser, setModalLockedUser] = useState(false);
	const [modalInactiveUser, setModalInactiveUser] = useState(false);
	const [modalAlertDown, setModalAlertDown] = useState({
		show: false,
		message: '',
	});
	// const [passwordEscapado, setPasswordEscapado] = useState(true);
	const [refreshCaptcha, setRefreshCaptcha] = useState(false);
	const [loading, setLoading] = useState(false);
	const [code, setCode] = useState('');
	const [codeInput, setCodeInput] = useState('');
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const theme = useTheme();
	const md = useMediaQuery(theme.breakpoints.down('md'));
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	//const [btnCalifica, setBtnCalifica] = useState(true);
	const [btnCalifica, setBtnCalifica] = useState(false);
	const [nssValidInput, setNssValidInput] = useState(false);
	const [alertNss, setAlertNss] = useState<IAlert>({
		show: false,
		message: '',
		severity: 'success',
	});
	const [modalNoAccount, setModalNoAccount] = useState({
		show: false,
		type: 1,
	});
	const [nameEncrypt, setNameEncrypt] = useState('');
	const [zoomCursor, setZoomCursor] = useState(false);
	const [isDislexic, setIsDislexic] = useState(false);
	const [isGrayScale, setIsGrayScale] = useState(false);
	const [isContrast, setIsContrast] = useState(false);
	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);

	useEffect(() => {
		// setInitPoll();  // Se comenta temporal
	}, []);

	const setData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		if (name === 'nss') {
			if (isNumber(value)) {
				let validate = { valid: false, msg: '' };
				if (value.length > 11) return;
				validate = nssValid(value);
				setCredentials({ ...credentials, [name]: value });
				setErrorNSS(validate);
			} else {
				setModalConfirm(true);
			}
		} else {
			setCredentials({ ...credentials, [name]: value });
		}
	};

	const handleValidate = (): void => {
		if (!errorNSS.valid || !credentials.nss) {
			return setAlert({
				show: true,
				message: INTERNAL_MESSAGES.SOCIAL_SEGURITY_NUMBRE,
				severity: 'warning',
			});
		} else if (!credentials.password) {
			return setAlert({
				show: true,
				message: INTERNAL_MESSAGES.PASSWORD_FIELD,
				severity: 'warning',
			});
		} else if (!code || codeInput !== code) {
			return setAlert({
				show: true,
				message: INTERNAL_MESSAGES.CAPTCHA_ERROR,
				severity: 'error',
			});
		}
		const isNSS = nssValid(credentials.nss);
		if (isNSS.valid) {
			handleOnSubmit();
		} else {
			setModalConfirm(!modalConfirm);
		}
	};

	const handleOnSubmit = async () => {
		setLoading(true);
		setModalConfirm(false);
		setAlert({
			show: false,
			message: '',
			severity: 'error',
		});
		try {
			const keyRes = await keyAPI.get<'', { k: string }>();
			const key = keyRes['k'].toString();
			const email_pass_base64 = await encrypt(
				credentials.nss + ':' + credentials.password,
				key
			);
			localStorage.removeItem('parameters');
			authAPI
				.post<
					{ username: string; password: string },
					{
						code: number | string;
						login: boolean;
						isPassedAway: number | string;
						token: string;
						accountStatus?: string;
						description?: string;
						fallecido?: boolean;
						perfil?: string;
					}
				>('', {
					username: email_pass_base64,
					password: email_pass_base64,
				})
				.then((res) => {
					if (res?.login) {
						if (!isEmpty(res.token)) {
							const user = jwt(res.token) as any;
							setAlert({
								show: true,
								message: INTERNAL_MESSAGES.CORRECT_LOGIN,
								severity: 'success',
							});
							dispatch(
								actions.setUser({
									...user,
									gender: user.curp[10] || 'H',
									k: key,
									token: res.token,
									isImpersonalized: false,
								})
							);
							dispatch(
								creditsAction.setAvailableCredits([
									...user.creditos,
								])
							);
							dispatch(actions.changeValidatePensionado(true));
							dispatch(actions.changeValidateCorreccion(true));
							dispatch(actions.changeValidateData(true));
							if (user?.creditos.length > 0) {
								if (user?.creditos[0]?.num_credito) {
									dispatch(
										creditsAction.setCredit(
											user.creditos[0].num_credito
										)
									);
								}
							}
							dispatch(saldAction.cleanSaldo());
							navigate('/');
							console.log('v1_0_307_h');
						} else {
							setAlert({
								show: true,
								message:
									INTERNAL_MESSAGES.ERROR_GETTING_INFORMATION,
								severity: 'warning',
							});
						}
					} else if (res.fallecido) {
						if (res.perfil === PROFILES.BENEFICIARIO_SIN_CREDITO) {
							setModalAlertDown({
								show: true,
								message: MESSAGES.BENEFICIARIO_SIN_CREDITO,
							});
						} else if (
							res.perfil === PROFILES.BENEFICIARIO_CREDITO_VIGENTE
						) {
							setModalAlertDown({
								show: true,
								message: MESSAGES.BENEFICIARIO_CREDITO_VIGENTE,
							});
						} else if (
							res.perfil === PROFILES.BENEFICIARIO_CREDITO_CERRADO
						) {
							setModalAlertDown({
								show: true,
								message: MESSAGES.BENEFICIARIO_CREDITO_CERRADO,
							});
						} else {
							setAlert({
								show: true,
								message: res.description
									? res.description
									: INTERNAL_MESSAGES.ERROR_MESSAGE,
								severity: 'error',
							});
						}
					} else if (res.accountStatus === 'locked') {
						setModalLockedUser(true);
					} else if (res.accountStatus === 'inactive') {
						setModalInactiveUser(true);
					} else if (Number(res.code) === 409) {
						setModalNoAccount({
							show: true,
							type: 4,
						});
					} else {
						if (
							res.description === INTERNAL_MESSAGES.NOT_REGISTERED
						) {
							setModalNoAccount({
								show: true,
								type: 3,
							});
						} else {
							setAlert({
								show: true,
								message: res.description
									? res.description
									: INTERNAL_MESSAGES.ERROR_MESSAGE,
								severity: 'error',
							});
						}
						setRefreshCaptcha(!refreshCaptcha);
					}
				})
				.catch((error) => {
					setAlert({
						show: true,
						message: error?.description
							? error?.description
							: INTERNAL_MESSAGES.ERROR_MESSAGE,
						severity: 'error',
					});
				})
				.finally(() => {
					setLoading(false);
				});
		} catch (error) {
			setAlert({
				show: true,
				message: INTERNAL_MESSAGES.ERROR_MESSAGE,
				severity: 'error',
			});
			setRefreshCaptcha(!refreshCaptcha);
			setLoading(false);
		}
	};

	const handleValidateNss = async () => {
		if (USERS_BLOCKED.includes(credentials.nss)) {
			setAlertNss({
				show: true,
				message: MESSAGES.USERS_BLOCKED,
				severity: 'error',
			});
		} else {
			setLoading(true);
			setAlertNss({
				show: false,
				message: '',
				severity: 'success',
			});
			try {
				const keyRes = await keyAPI.get<'', { k: string }>();
				const key = keyRes['k'].toString();
				const paramsData = {
					nss: credentials.nss,
				};
				const dataSendEncrypt = await encrypt(
					JSON.stringify(paramsData),
					key
				);
				validateNss
					.post<{ data: string }, any>('', {
						data: dataSendEncrypt,
					})
					.then((res: any) => {
						if (res?.result?.fallecido) {
							if (
								res?.result?.perfil ===
								PROFILES.BENEFICIARIO_SIN_CREDITO
							) {
								setModalAlertDown({
									show: true,
									message: MESSAGES.BENEFICIARIO_SIN_CREDITO,
								});
							} else if (
								res?.result?.perfil ===
								PROFILES.BENEFICIARIO_CREDITO_VIGENTE
							) {
								setModalAlertDown({
									show: true,
									message: MESSAGES.BENEFICIARIO_CREDITO_VIGENTE,
								});
							} else if (
								res?.result?.perfil ===
								PROFILES.BENEFICIARIO_CREDITO_CERRADO
							) {
								setModalAlertDown({
									show: true,
									message: MESSAGES.BENEFICIARIO_CREDITO_CERRADO,
								});
							}
						} else {
							if (
								res?.result?.data &&
								res?.result?.data.trimStart().length > 0
							) {
								setNssValidInput(true);
								setNameEncrypt(res?.result?.data);
							} else {
								setModalNoAccount({
									show: true,
									type: 1,
								});
							}
						}
					})
					.catch((error: any) => {
						setAlertNss({
							show: true,
							message: error?.description
								? error?.description
								: INTERNAL_MESSAGES.ERROR_MESSAGE,
							severity: 'error',
						});
					})
					.finally(() => {
						setLoading(false);
					});
			} catch (error) {
				setAlertNss({
					show: true,
					message: INTERNAL_MESSAGES.ERROR_MESSAGE,
					severity: 'error',
				});
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	return (
		<div className={`${classes.container} 
			${zoomCursor ? 'custom-cursor' : ''} 
			${isDislexic ? 'fontDislexic' : ''} 
			${isGrayScale ? 'grayScales' : ''}
			${isContrast ? 'activeContrast' : ''}`}
			ref={divRef}>
			<ModalAuxiliar show={btnCalifica} setShow={setBtnCalifica} />
			<ExpandButton
				id="ChatBotero"
				link="/#"
				title="Asistente Virtual Infonavit"
				icon={chatBot}
				iconStyle={{ height: 40 }}
				style={{ position: 'absolute', right: 0, top: '71%' }}
				Click={() => {
					clasChat.showChat();
				}}
			/>

			{/* Se comenta temporalmente y se deja fijo el boton */}
			{/* {btnCalifica && (
				<ExpandButton
					link="#Experiencia"
					title="Califica tu experiencia"
					icon={likeIcon}
					iconStyle={{ height: 40 }}
					style={{ position: 'absolute', right: 0, top: '84%' }}
					Click={() => {
						setBtnCalifica(false);
						showModal();
					}}
				/>
			)} */}
			<ExpandButton
				link="#Experiencia"
				title="Califica tu experiencia"
				icon={likeIcon}
				iconStyle={{ height: 40 }}
				style={{ position: 'absolute', right: 0, top: '84%' }}
				Click={() => {
					setBtnCalifica(true);
				}}
			/>
			<Grid className={classes.subContainer} container spacing={0}>
				<Grid
					className={classes.mainContainerImgs}
					item
					xs={12}
					sm={12}
					md={7}
					lg={8}
				>
					<div className={classes.containerImgs}>
						<div className={classes.subContainerImgs}>
							<img
								src={logoLogin}
								className={classes.imgLogoInfonavit}
								alt="logo-infonavit"
							/>
						</div>
						<p className={classes.textInformation}>
							Mi Cuenta Infonavit te permite consultar tu
							información y hacer trámites en línea relacionados
							con tu crédito
						</p>
						<p className={classes.textInformationIcon}>
							<b>
								<ErrorOutlineIcon className={classes.IconInfo} />
								Recuerda que todos los trámites en el Infonavit son gratuitos
							</b>
						</p>
						<div className={classes.containerButtons}>
							<button
								onClick={() => navigate('/oficinas-atencion')}
								className={classes.buttonMainSection}
							>
								<img
									src={iconAttention}
									className={classes.imgIconAttention}
									alt="ícono oficinas de atención"
								/>
								Oficinas de atención
							</button>
							<button
								className={classes.buttonMainSection}
								onClick={() => navigate('/canales-servicio')}
							>
								<img
									src={iconServices}
									className={classes.imgIconAttention}
									alt="ícono canales de servicio"
								/>
								Canales de servicio
							</button>
						</div>
					</div>
				</Grid>
				<Grid
					className={
						!nssValidInput
							? classes.mainContainerFormCustom
							: classes.mainContainerForm
					}
					item
					xs={12}
					sm={12}
					md={5}
					lg={4}
				>
					<Paper className={classes.containerForm}>
						<div
							className={classes.containerConsultNSS}
							style={{ marginTop: 0 }}
						>
							<a
								href={require('../../assets/files/aviso-de-privacidad.pdf')}
								target={'_blank'}
								style={{ textDecoration: 'none' }}
								download="aviso-de-privacidad.pdf"
								className={classes.labelConsultNSS}
							>
								<span style={{ marginRight: 5, color: 'black' }}>Consulta el</span>
								<span>Aviso de Privacidad</span>
							</a>
						</div>

						{nssValidInput && (
							<div className={classes.containerButtonsForm}>
								<p className={classes.labelContainerInput}>
									Bienvenido
								</p>
								<p className={classes.nameUser}>
									{nameEncrypt}
								</p>
								<div className={classes.containerConsultNSS}>
									<a
										onClick={() => {
											setModalNoAccount({
												show: true,
												type: 2,
											});
										}}
										className={classes.labelConsultNSS}
										target="_blank"
										style={{ textDecoration: 'underline' }}
									>
										No soy yo
									</a>
								</div>
							</div>
						)}

						{!nssValidInput && (
							<div className={classes.containerButtonsForm}>
								<TextInput
									id="nss"
									name="nss"
									labelComponent={
										<p
											className={
												classes.labelContainerInput
											}
										>
											Número de Seguridad Social (NSS)
										</p>
									}
									labelStyles={{ fontSize: 16 }}
									value={credentials.nss}
									onChange={setData}
									error={!errorNSS.valid}
									helperText={
										!errorNSS.valid ? errorNSS.msg : ''
									}
									type="password"
								/>
								<div className={classes.containerConsultNSS}>
									<a
										href="https://serviciosdigitales.imss.gob.mx/gestionAsegurados-web-externo/asignacionNSS"
										className={classes.labelConsultNSS}
										target="_blank"
									>
										Consulta tu Número de Seguridad Social
									</a>
								</div>

								<div className={classes.containerButtonsForm}>
									<CustomAlert
										message={alertNss.message}
										severity={alertNss.severity}
										show={alertNss.show}
										onClose={() => {
											setAlertNss({
												show: false,
												message: '',
												severity: alertNss.severity,
											});
										}}
									/>
									<CustomButton
										onClick={handleValidateNss}
										styles={{ marginBottom: 20 }}
										variant="solid"
										label="Continuar"
										disabled={!errorNSS.valid}
									/>
								</div>
							</div>
						)}

						{nssValidInput && (
							<>
								<TextInput
									id="password"
									name="password"
									label="Contraseña"
									type="password"
									labelStyles={{ fontSize: 16 }}
									value={credentials.password}
									onChange={setData}
								/>
								<div className={classes.containerConsultNSS}>
									<Link
										to="/cambio-contrasenia"
										className={classes.labelForgotPass}
									>
										¿Olvidaste tu contraseña?
									</Link>
								</div>
								<div className={classes.containerCaptcha}>
									<Captcha
										value={codeInput}
										getNew={refreshCaptcha}
										onChange={(
											newValue: string,
											codeCaptcha: string
										) => {
											setCodeInput(newValue);
											setCode(codeCaptcha);
										}}
									/>
								</div>
							</>
						)}
						<div className={classes.containerButtonsForm}>
							<CustomAlert
								message={alert.message}
								severity={alert.severity}
								show={alert.show}
								onClose={() => {
									setAlert({
										show: false,
										message: '',
										severity: alert.severity,
									});
								}}
							/>
							<CustomButton
								onClick={handleValidate}
								styles={{ marginBottom: 20 }}
								variant="solid"
								label="Iniciar sesión"
								disabled={!nssValidInput}
							/>
							<div className={classes.dividerButtons} />
							<CustomButton
								onClick={() => navigate('/registro')}
								variant="outlined"
								styles={{ marginBottom: 20 }}
								label="Crear una cuenta"
							/>
						</div>
					</Paper>
				</Grid>
				<div className={classes.containerButtonsSmall}>
					<button
						onClick={() => navigate('/oficinas-atencion')}
						className={classes.buttonMainSection}
					>
						<img
							src={iconAttention}
							className={classes.imgIconAttention}
							alt="ícono oficinas de atención"
						/>
						Oficinas de atención
					</button>
					<button
						className={classes.buttonMainSection}
						onClick={() => navigate('/canales-servicio')}
					>
						<img
							src={iconServices}
							className={classes.imgIconAttention}
							alt="ícono canales de servicio"
						/>
						Canales de servicio
					</button>
				</div>
			</Grid>
			<ModalLoading loading={loading} />

			<BaseModal
				open={modalNoAccount.show}
				width={md ? 'md' : 'sm'}
				onClose={() => {
					setModalNoAccount({
						...modalNoAccount,
						show: !modalNoAccount,
					});
				}}
			>
				<div className={classes.containerModalInfo}>
					{modalNoAccount.type === 1 && (
						<>
							<img
								src={warning}
								alt="ícono advertencia"
								data-testid="imgWarning"
							/>
							<p>{INTERNAL_MESSAGES.NOT_CREATE_ACCOUNT}</p>
							<div className={classes.containerModalNoAccount}>
								<CustomButton
									onClick={() => {
										setModalNoAccount({
											...modalNoAccount,
											show: !modalNoAccount,
										});
									}}
									variant="outlined"
									styles={{ marginBottom: 20 }}
									label="Salir"
								/>
								<CustomButton
									onClick={() => navigate('/registro')}
									variant="solid"
									styles={{ marginBottom: 20 }}
									label="Crear cuenta"
								/>
							</div>
						</>
					)}
					{modalNoAccount.type === 2 && (
						<>
							<img
								src={warning}
								alt="ícono advertencia"
								data-testid="imgWarning"
							/>
							<p>
								Si tu Número de Seguridad Social (NSS) no
								coincide con las iniciales de tu nombre,
								comunícate al Infonatel al 55 9171 5050 o desde
								cualquier parte del país al 800 008 39 00 y
								levanta un caso de aclaración.
							</p>
							<div className={classes.containerBtnAcceptModal}>
								<CustomButton
									onClick={() => {
										setModalNoAccount({
											...modalNoAccount,
											show: !modalNoAccount,
										});
										window.location.reload();
									}}
									variant="outlined"
									styles={{ marginBottom: 20 }}
									label="Salir"
								/>
							</div>
						</>
					)}
					{modalNoAccount.type === 3 && (
						<>
							<img
								src={warning}
								alt="ícono advertencia"
								data-testid="imgWarning"
							/>
							<p>{INTERNAL_MESSAGES.NOT_REGISTERED}</p>
							<div className={classes.containerModalNoAccount}>
								<CustomButton
									onClick={() => {
										setModalNoAccount({
											...modalNoAccount,
											show: !modalNoAccount,
										});
									}}
									variant="outlined"
									styles={{ marginBottom: 20 }}
									label="Salir"
								/>
								<CustomButton
									onClick={() => navigate('/registro')}
									variant="solid"
									styles={{ marginBottom: 20 }}
									label="Crear cuenta"
								/>
							</div>
						</>
					)}
					{modalNoAccount.type === 4 && (
						<>
							<img
								src={warning}
								alt="warning"
								data-testid="imgWarning"
							/>
							<p>{INTERNAL_MESSAGES.ACTIVE_SESION}</p>
							<div className={classes.containerBtnAcceptModal}>
								<CustomButton
									onClick={() => {
										setModalNoAccount({
											...modalNoAccount,
											show: !modalNoAccount,
										});
									}}
									variant="solid"
									styles={{ marginBottom: 20 }}
									label="Continuar"
								/>
							</div>
						</>
					)}
				</div>
			</BaseModal>

			<BaseModal
				open={modalConfirm}
				width={md ? 'md' : 'sm'}
				onClose={() => setModalConfirm(!modalConfirm)}
			>
				<div className={classes.containerModalInfo}>
					<img src={warning} alt="ícono advertencia" data-testid="imgWarning" />
					<p>
						Mejoramos la seguridad para proteger tu información,
						ahora ingresa a Mi Cuenta Infonavit con tu Número de
						Seguridad Social.
					</p>
					<div className={classes.containerBtnAcceptModal}>
						<CustomButton
							data-testid="buttonOneAceptar"
							onClick={() => setModalConfirm(!modalConfirm)}
							variant="solid"
							styles={{ marginBottom: 20 }}
							label="Salir"
						/>
					</div>
				</div>
			</BaseModal>

			<BaseModal
				open={modalAlertDown.show}
				width={md ? 'md' : 'sm'}
				onClose={() =>
					setModalAlertDown({
						show: false,
						message: '',
					})
				}
			>
				<div className={classes.containerModalInfo}>
					<img src={warning} alt="ícono advertencia" />
					<p
						dangerouslySetInnerHTML={{
							__html: modalAlertDown.message,
						}}
					></p>
					<div className={classes.containerBtnAcceptModal}>
						<CustomButton
							data-testid="buttontwoAceptar"
							onClick={() =>
								setModalAlertDown({
									show: false,
									message: '',
								})
							}
							variant="solid"
							styles={{ marginBottom: 20 }}
							label="Salir"
						/>
					</div>
				</div>
			</BaseModal>

			<ModalConfirmation
				open={modalLockedUser}
				confirmLabel="Salir"
				onConfirm={() => setModalLockedUser(!modalLockedUser)}
				width="sm"
			>
				<p className={classes.titleModalAlert}>
					El usuario se encuentra bloqueado, para poder desbloquearlo
					favor de visitar el siguiente link:{' '}
					<Link
						to="/cambio-contrasenia"
						className={classes.labelForgotPass}
					>
						Restaurar usuario
					</Link>
					<br />
				</p>
			</ModalConfirmation>

			<ModalConfirmation
				open={modalInactiveUser}
				confirmLabel="Salir"
				onConfirm={() => setModalInactiveUser(!modalInactiveUser)}
				width="sm"
			>
				<p className={classes.titleModalAlert}>
					Detectamos que tienes tu registro incompleto, por favor
					ingresa el código de confirmación que enviamos al correo
					electrónico o al número de teléfono que registraste (revisa
					tu bandeja de SPAM).
					<br />
				</p>
			</ModalConfirmation>

			<AccessibilityTool
				zoomCursor={zoomCursor}
				setZoomCursor={setZoomCursor}
				isDislexic={isDislexic}
				setIsDislexic={setIsDislexic}
				isGrayScale={isGrayScale}
				setIsGrayScale={setIsGrayScale}
				isContrast={isContrast}
				setIsContrast={setIsContrast}
				updateText={updateTagText}
			/>
			<Footer />
		</div>
	);
};

export default Login;
