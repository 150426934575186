/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react'
import PersonalData from './personalData';
import Password from './password';
import ContactData from './contactData';
import Layout from '../../components/Layout';
import Tabs from '../../components/Tabs';
import Confirmation from './confirmation';
import { useStyles } from './styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../../components/Button';
import { Grid, Paper, Divider } from '@mui/material';

import VerificationCode from '../../components/VerificationCode';
import ModalLoading from '../../components/ModalLoading';
import BaseModal from '../../components/Modal';
import VerificationEmail from './VirificationEmail';
import VerificationUser from './verificationUser';
import { useRegister } from './hooks/useRegister';
import { TABS_REGISTER_VALUES } from './utils/tabs.utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const Register = () => {
	const { fontSizeLevel } = useSelector((state: RootState) => state.session);
	const classes = useStyles();
	const theme = useTheme();
	const md = useMediaQuery(theme.breakpoints.down('md'));
	const [updateTagText, setUpdateTagText] = useState(false);
	const divRef = useRef(null);
	const {
		handleFirstStep,
		handleSecondStep,
		handleThirdStep,
		handleCreateAccount,
		setUserDataRegister,
		handleResendCodeSms,
		handleResendEmail,
		handleContinueNSS,
		createCaso,
		setData,
		alert,
		loading,
		isActivated,
		modalConfirm,
		superError,
		isActivatedEmail,
		noResend,
		modalNameIncorrect,
		setModalNameIncorrect,
		messageNameIncorrect,
		messageVerification,
		containerPosition,
		tabActive,
		tabsEle,
		changeTabPrevious,
		setModalConfirm,
		userData,
		setAlert,
		changeTabNext,
		dataUser,
		setIsActivatedEmail,
	} = useRegister();

	useEffect(() => {
		let observer: any;
		const initializeObserver = () => {
			if (divRef.current && (fontSizeLevel !== undefined && fontSizeLevel !== 0)) {
				observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === 'childList') {
							setUpdateTagText(true);
							setTimeout(() => {
								setUpdateTagText(false);
							}, 100);
						}
					});
				});

				observer.observe(divRef.current, {
					childList: true,
					attributes: true,
					subtree: true,
				});
			}
		};

		if (document.readyState === 'complete') {
			initializeObserver();
		} else {
			window.addEventListener('DOMContentLoaded', initializeObserver);
		}
		return () => {
			if (divRef.current && observer) {
				observer.disconnect();
			}
			window.removeEventListener('DOMContentLoaded', initializeObserver);
		};
	}, [fontSizeLevel, divRef]);

	return (
		<Layout onlyTitle title="Crear una cuenta" updateTagText={updateTagText}>
			<div ref={divRef}>
				<Tabs
					tabs={tabsEle}
					cardsType
					containerPosition={containerPosition}
					active={tabActive}
				>
					<Paper>
						{tabActive === TABS_REGISTER_VALUES.PERSONAL_DATA && (
							<PersonalData
								onContinue={handleFirstStep}
								onContinueNSS={handleContinueNSS}
								onChange={setUserDataRegister}
								hasError={alert}
								setDataUser={setData}
								onCloseError={() => {
									setAlert({
										show: false,
										message: '',
										severity: alert.severity,
									});
								}}
							/>
						)}
						{tabActive === TABS_REGISTER_VALUES.VERIFY_NAME && (
							<VerificationUser
								infoUser={userData.name}
								onContinue={handleSecondStep}
								goBack={() => {
									setAlert({
										show: false,
										message: '',
										severity: alert.severity,
									});
									changeTabPrevious();
								}}
								createCaso={createCaso}
								hasError={alert}
								dataUser={dataUser}
								messageVerification={messageVerification}
								onCloseError={() => {
									setAlert({
										show: false,
										message: '',
										severity: alert.severity,
									});
								}}
							/>
						)}
						{tabActive === TABS_REGISTER_VALUES.CONTACT_DATA && (
							<ContactData
								onContinue={() => changeTabNext()}
								onChange={setUserDataRegister}
								hasError={alert}
								onCloseError={() => {
									setAlert({
										show: false,
										message: '',
										severity: alert.severity,
									});
								}}
							/>
						)}
						{tabActive === TABS_REGISTER_VALUES.PASSWORD && (
							<Password
								onContinue={handleThirdStep}
								onChange={setUserDataRegister}
								hasError={alert}
								onCloseError={() => {
									setAlert({
										show: false,
										message: '',
										severity: alert.severity,
									});
								}}
							/>
						)}
						{tabActive === TABS_REGISTER_VALUES.ACCOUNT && (
							<Grid
								container
								className={classes.container}
								columns={12}
								spacing={0}
							>
								<Grid item xs={0} sm={0} md={3} lg={3} />
								<Grid item xs={12} sm={12} md={6} lg={6}>
									{!isActivatedEmail ? (
										<VerificationEmail
											hasTimer
											onResend={handleResendEmail}
											onContinue={() =>
												setIsActivatedEmail(true)
											}
											isActivated={isActivated}
											hasError={alert}
											superError={superError}
											noResend={noResend}
											onCloseError={() => {
												setAlert({
													show: false,
													message: '',
													severity: alert.severity,
												});
											}}
										/>
									) : (
										<VerificationCode
											isEmail={false}
											setCode={handleCreateAccount}
											reSend={handleResendCodeSms}
											hasError={alert}
											onCloseError={() => {
												setAlert({
													show: false,
													message: '',
													severity: alert.severity,
												});
											}}
											isSixDigits={true}
										/>
									)}
								</Grid>
								<Grid item xs={0} sm={0} md={3} lg={3} />
							</Grid>
						)}
						{tabActive === TABS_REGISTER_VALUES.CONFIRMATION && (
							<Confirmation />
						)}
						{(tabActive === TABS_REGISTER_VALUES.PERSONAL_DATA ||
							tabActive === TABS_REGISTER_VALUES.CONTACT_DATA ||
							tabActive === TABS_REGISTER_VALUES.PASSWORD) && (
								<div className={classes.containerRequiredFields}>
									<Divider />
									<p className={classes.requiredFieldsLabel}>
										* Datos obligatorios
									</p>
								</div>
							)}
						<ModalLoading loading={loading} />
						<BaseModal
							title="Confirmar"
							open={modalConfirm}
							width="sm"
							onClose={() => setModalConfirm(!modalConfirm)}
							onConfirm={() => null}
						>
							<p>¿Estás seguro de crear la cuenta?</p>
						</BaseModal>
						<BaseModal
							open={modalNameIncorrect}
							width={md ? 'md' : 'sm'}
							onClose={() => {
								setModalNameIncorrect(!modalNameIncorrect);
								handleSecondStep();
							}}
						>
							<div className={classes.containerModalInfo}>
								<p>{messageNameIncorrect}</p>
								<div className={classes.containerBtnAcceptModal}>
									<CustomButton
										data-testid="buttonAceptar"
										onClick={() => {
											setModalNameIncorrect(
												!modalNameIncorrect
											);
											handleSecondStep();
										}}
										variant="solid"
										styles={{ marginBottom: 20 }}
										label="Aceptar"
									/>
								</div>
							</div>
						</BaseModal>
					</Paper>
				</Tabs>
			</div>
		</Layout>
	);
};

export default Register;
